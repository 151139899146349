<template>
  <div class="card reset-password-form reset-password-child">
    <h1>{{ $t("resetPassword.title") }}</h1>
    <v-form
      ref="form"
      v-model="formValid"
      @submit.prevent="handleSubmit"
      lazy-validation
    >
      <div>
        <!-- password -->
        <v-text-field
          v-model="formData.password"
          :rules="[$rules.required, $rules.password]"
          type="password"
          name="password"
          :label="$t('login.password')"
          filled
          prepend-inner-icon="mdi-key"
        ></v-text-field>

        <!-- password confirm -->
        <v-text-field
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show = !show"
          v-model="formData.password_confirmation"
          :rules="[
            $rules.required,
            $rules.password,
            $rules.same($t('login.password'), formData.password),
          ]"
          :type="show ? 'text' : 'password'"
          name="passwordConfirm"
          :label="$t('login.passwordConfirm')"
          filled
          prepend-inner-icon="mdi-key"
        ></v-text-field>

        <!-- return to login -->
        <router-link class="return-login" :to="{ name: 'auth.login' }">
          {{ $t("login.returnToLogin") }}
        </router-link>
      </div>

      <v-spacer />

      <div>
        <api-response :response="formResponse" class="mt-5" />

        <!-- set new password -->
        <v-btn
          color="primary"
          type="submit"
          :disabled="!formValid || !Object.keys(formData).length"
          :loading="$loading('auth/forgotPassword')"
          block
        >
          {{ $t("resetPassword.title") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    formData: {},
    formValid: false,
    formResponse: null,
    show: false,
  }),

  computed: {
    isDisabled() {
      return !this.formValid;
    },
  },

  methods: {
    handleSubmit() {
      const data = {
        ...this.formData,
        token: this.$route.query.token,
        email: this.$route.params.email,
      };

      this.formResponse = null;
      this.$store
        .dispatch("auth/resetPassword", data)
        .then(() => {
          this.$emit("success");
        })
        .catch((err) => {
          this.formResponse = err.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password-form {
  h1 {
    margin-bottom: 65px;
  }
  form {
    text-align: right;
    .return-login {
      display: inline-block;
      margin-bottom: 49px;
    }
  }
}
</style>

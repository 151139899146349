<template>
  <div class="card reset-password-success reset-password-child">
    <h1>{{ $t("resetPassword.success.heading") }}</h1>
    <div class="subtitle-1 text-center">
      {{ $t("resetPassword.success.text") }}
    </div>
    <router-link class="return-login" :to="{ name: 'auth.login' }">
      {{ $t("login.returnToLogin") }}
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.reset-password-success {
  h1 {
    margin-bottom: 85px;
  }
}
</style>

<template>
  <div class="reset-password">
    <ResetPasswordForm v-if="isForm" @success="isForm = false" />

    <ResetPasswordSuccess v-else />
  </div>
</template>

<script>
import ResetPasswordForm from "@/components/auth/reset-password/ResetPasswordForm";
import ResetPasswordSuccess from "@/components/auth/reset-password/ResetPasswordSuccess";

export default {
  components: { ResetPasswordForm, ResetPasswordSuccess },

  data: () => ({
    isForm: true,
  }),
};
</script>

<style lang="scss">
.reset-password {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  .reset-password-child {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .mdi-eye-off {
    opacity: 0.5;
  }
}
</style>
